import React from 'react'
import find from 'lodash/find'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Container from '../components/Container'
import {graphql} from "gatsby"
import Layout from '../components/DefaultLayout'
import Img from 'gatsby-image';
import styled from 'styled-components'
import DogLinks from "../components/DogLinks";

const Wrapper = styled.div`
    .container { 
    display: flex;
    flex-direction: column;
    
    @media screen and (min-width: ${props => props.theme.responsive.medium}) {
        flex-direction: row;
    }
    .left {
        flex: 2;
        
        @media screen and (min-width:${props => props.theme.responsive.medium}) {
        margin-right: 3rem;
        }
    }
    .right {
        flex: 3;
    }
   }
   
   .treeUrl{
    word-break: break-all;
   }
  h1,
  h2,
  h3 {
    font-weight: 600;
    line-height: 1.25;
    margin: 0 0 1rem 0;
    text-transform: capitalize;
    color: ${props => props.theme.colors.base};
  }

  h1 {
    font-size: 3em;
    text-align: center;
    @media screen and (min-width:${props => props.theme.responsive.medium}) {
        text-align: initial;
    }
  }
  h2 {
    font-size: 2em;
  }
  h3 {
    font-size: 1em;
  }

  p {
  color: white;
    line-height: 1.6;
    margin: 0 0 2em 0;
  }

  a {
    transition: 0.2s;
    color: ${props => props.theme.colors.base};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }

  del {
    text-decoration: line-through;
  }
  strong {
    font-weight: 600;
  }
  em {
    font-style: italic;
  }

  ul,
  ol {
    margin: 0 0 2em 0;
  }

  ul {
    li {
      list-style: disc;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 0;
      }
    }
  }

  ol {
    li {
      list-style: decimal;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 0;
      }
    }
  }

  hr {
    border-style: solid;
    border-color: ${props => props.theme.colors.secondary};
    margin: 0 0 2em 0;
  }

  blockquote {
    font-style: italic;
    border-left: 4px solid ${props => props.theme.colors.secondary};
    padding: 0 0 0 0.5em;
  }

  pre {
    margin: 0 0 2em 0;
    border-radius: 2px;
    background: ${props => props.theme.colors.secondary} !important;
    span {
      background: inherit !important;
    }
  }
`


const DogTemplate = ({data}) => {
    const {
        name,
        slug,
        id,
        image,
        dateOfBirth,
        titles,
        familyTreeUrl
    } = data.contentfulDog;

    const postIndex = find(
        data.allContentfulDog.edges,
        ({node: dog}) => dog.id === id
    );

    return (
        <Layout>
            <div>
                <Helmet>
                    <title>{`${name} - ${config.siteTitle}`}</title>
                    <meta property="og:title" content={`${name} - ${config.siteTitle}`}/>
                    <meta property="og:url" content={`${config.siteUrl}/${slug}/`}/>
                    <meta property="og:image" content={image.sizes.src}/>
                </Helmet>
                <Container>
                    <Wrapper>
                        <h1>{name}</h1>
                        <div className="container">
                            <div className="left">
                                <Img sizes={image.sizes} title={image.title}/>
                            </div>
                            <div className="right">
                                <h2>Date of Birth</h2>
                                <p>{dateOfBirth}</p>
                                {titles &&
                                <div>
                                    <h2>Titles</h2>
                                    <ul>
                                        {titles.map((title) => (
                                            <li key={title}>{title}</li>
                                        ))}
                                    </ul>
                                </div>
                                }
                                <h2>Family Tree</h2>
                                <a href={familyTreeUrl} className="treeUrl">{familyTreeUrl}</a>
                            </div>
                        </div>
                    </Wrapper>
                    <DogLinks previous={postIndex.previous} next={postIndex.next}/>
                </Container>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query dogQuery($slug: String!) {
        contentfulDog(slug: {eq: $slug}) {
            name
            id
            slug
            image {
                title
                sizes(maxWidth: 1800) {
                    ...GatsbyContentfulSizes_withWebp_noBase64
                }
            }
            dateOfBirth
            titles
            familyTreeUrl
        }
        allContentfulDog(
            limit: 1000
        ) {
            edges {
                node {
                    id
                }
                previous {
                    slug
                }
                next {
                    slug
                }
            }
        }
    }
`

export default DogTemplate
