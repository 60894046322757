import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  margin-top: 2rem;
  a {
    background: ${props => props.theme.colors.base};
    color: white;
    padding: 1em;
    border-radius: 2px;
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      background: ${props => props.theme.colors.highlight};
    }
  }
`

const PreviousLink = styled(Link)`
  margin-right: auto;
  order: 1;
`

const NextLink = styled(Link)`
  margin-left: auto;
  order: 2;
`

const DogLinks = props => {
    return (
        <Wrapper>
            {props.previous && (
                <PreviousLink to={`/dog/${props.previous.slug}/`}>Prev Dog</PreviousLink>
            )}
            {props.next && <NextLink to={`/dog/${props.next.slug}/`}>Next Dog</NextLink>}
        </Wrapper>
    )
}

export default DogLinks
